import React, { useState, useEffect } from "react";

const InventoryPopup = ({ isOpen, onClose, imovelId }) => {
  const [inventoryData, setInventoryData] = useState([]);
  const [newInventory, setNewInventory] = useState({
    cap_medio: "",
    altura_media: "",
    dap_medio: "",
  });

  useEffect(() => {
    if (isOpen) fetchInventory();
  }, [isOpen]);

  const fetchInventory = async () => {
    try {
      const response = await fetch(
        `http://localhost:5000/api/imoveis/${imovelId}/inventario`
      );
      const data = await response.json();
      setInventoryData(data);
    } catch (error) {
      console.error("Erro ao buscar inventário:", error);
    }
  };

  const handleChange = (e) => {
    setNewInventory({ ...newInventory, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `http://localhost:5000/api/imoveis/${imovelId}/inventario`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newInventory),
        }
      );
      if (!response.ok) throw new Error("Erro ao registrar inventário");
      fetchInventory();
      setNewInventory({ cap_medio: "", altura_media: "", dap_medio: "" });
    } catch (error) {
      console.error("Erro ao registrar inventário:", error);
    }
  };

  return isOpen ? (
    <div className="modal">
      <h2>Inventário</h2>
      <form>
        <div className="form-group">
          <label>CAP Médio</label>
          <input
            type="number"
            step="0.01"
            name="cap_medio"
            value={newInventory.cap_medio}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Altura Média</label>
          <input
            type="number"
            step="0.01"
            name="altura_media"
            value={newInventory.altura_media}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>DAP Médio</label>
          <input
            type="number"
            step="0.01"
            name="dap_medio"
            value={newInventory.dap_medio}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <button type="button" onClick={handleSubmit} className="btn btn-primary">
          Adicionar
        </button>
        <button type="button" onClick={onClose} className="btn btn-secondary">
          Fechar
        </button>
      </form>

      <h3 className="mt-4">Inventário Existente</h3>
      {inventoryData.length > 0 ? (
        <ul>
          {inventoryData.map((item, idx) => (
            <li key={idx}>
              <strong>CAP Médio:</strong> {item.cap_medio} |{" "}
              <strong>Altura Média:</strong> {item.altura_media} |{" "}
              <strong>DAP Médio:</strong> {item.dap_medio}
            </li>
          ))}
        </ul>
      ) : (
        <p>Nenhum registro encontrado</p>
      )}
    </div>
  ) : null;
};

export default InventoryPopup;
